import React from "react"
import {StoryGroup} from "components";

const LinkMap = {
	'10 + "20"': '3c227d59016c',
	'0.1 + 0.2 == 0.3': '3c227d59016c',
	'add(2)(5)': '3c227d59016c',
	'(window.foo || ( window.foo = "bar" ))': '3c227d59016c',
	'foo.x?': '3c227d59016c',
}

const stories = [
	{
		title: "Answers of Front-end Job Interview Coding Questions",
		postId: "3c227d59016c",
	},
]

export const context = {
	stories: stories,
	linkMap: LinkMap,
	title: "FE Interview",
	path: 'fe-interview',
}

const FEInterviewPage = () => {

	return (
		<StoryGroup
			title={context.title}
			stories={context.stories}
			linkMap={context.linkMap}
		/>
	)
}

export default FEInterviewPage



